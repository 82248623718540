import getNextConfig from 'next/config';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import * as jwt from 'next-auth/jwt';

/**
 * Reads the JWT token from the next-auth session cookie, and returns the
 * session object by decoding the token. Returns null if the JWT token is absent
 * or invalid
 */
const env = getNextConfig().serverRuntimeConfig;
const envKey = 'NEXTAUTH_URL';

export async function getSessionFromCookie({
  req,
}: {
  req: { cookies: NextApiRequestCookies };
}) {
  process.env[envKey] = env[envKey];
  try {
    // The cookie name differs between http and https urls. Also see here:
    // https://github.com/nextauthjs/next-auth/blob/50fe115df6379fffe3f24408a1c8271284af660b/src/core/lib/cookie.ts#L56-L60
    const isSecure = env.NEXTAUTH_URL?.startsWith('https://');
    const cookiePrefix = isSecure ? '__Secure-' : '';
    const sessionToken =
      req.cookies?.[`${cookiePrefix}next-auth.session-token`];

    // decode will throw when the token is invalid
    const decoded = await jwt.decode({
      token: sessionToken,
      secret: String(env.NEXTAUTH_SECRET),
    });

    if (!decoded) return null;

    return {
      user: {
        id: decoded.id,
        email: decoded.email,
        isWhitelist: !!decoded.isWhitelist,
        isSuperAdmin: !!decoded.isSuperAdmin,
        isAdmin: !!decoded.isAdmin,
        isInternal: !!decoded.isInternal,
        signInProvider: decoded?.provider || 'credentials',
      },
      expires: new Date(Number(decoded.exp) * 1000).toISOString(),
    };
  } catch {
    return null;
  }
}
