import { GetServerSideProps } from 'next';
import { Router } from 'next/router';
import { Session } from 'next-auth';
import { getSession } from 'next-auth/react';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';

import Logo from 'components/Logo/Logo';
import { Button } from 'components/ui/button';
import useCommon from 'hooks/useCommon';
import { getSessionFromCookie } from 'utils/getSessionFromCookie';
import { ROUTES } from 'utils/routes';
import { pageMotionProps } from 'utils/styles/animations';
import { motion } from 'framer-motion';
import { pxToRems } from 'utils/styles/mixins';

export const getServerSideProps: GetServerSideProps = async ctx => {
  const session = await getSessionFromCookie({
    req: { cookies: ctx.req.cookies },
  });
  console.log('Homepage -- getServerSideProps -- session:', session);

  return {
    props: {
      initialSession: session,
    },
  };
};

interface HomePageProps {
  router: Router;
  initialSession: Session;
}

const HomePage: React.FunctionComponent<HomePageProps> = ({
  router,
  initialSession,
}) => {
  const [isReady, setReady] = useState(false);
  const { onLogout } = useCommon();
  const onContinue = useCallback(async () => {
    const session = await getSession();
    if (!session) {
      router.push(ROUTES.LOGIN);
      return;
    }
    if (session?.user?.isWhitelist) {
      router.push(ROUTES.WORKSPACE);
    } else {
      toast.info('Thank you for becoming a part of Euryka!', {
        id: 'is-whitelist',
        position: 'bottom-center',
        description:
          "Check your inbox for invite link. If you didn't receive the invite link, we'll invite you shortly.",
        duration: 13000,
        closeButton: true,
      });
    }
  }, [router]);

  const fetchOnce = useCallback(async () => {
    const session = await getSession();
    if (session?.user?.isWhitelist) {
      router.push(ROUTES.WORKSPACE);
      return;
    }
    setReady(true);
  }, [router]);
  useEffect(() => {
    fetchOnce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isReady) return <></>;
  return (
    <>
      <motion.div
        {...pageMotionProps}
        className={'grid place-items-center min-h-screen px-5'}
      >
        <div
          className={'w-full max-w-[987px] justify-self-center mt-8 mb-14'}
          style={{
            alignSelf: 'center',
          }}
        >
          <Logo className={'w-16'} />
          <div className={'mt-3 mb-8 max-w-[610px]'}>
            <h1
              className={
                'scroll-m-20 text-5xl font-light tracking-tight md:text-6xl lg:text-7xl'
              }
            >
              /Imagine:
              <br />
              Everything&nbsp;|&nbsp;The Intersection of AI and Productivity
            </h1>
          </div>

          <Button onClick={onContinue} className={'mb-3'}>
            {'Continue'}
          </Button>
          {initialSession?.user?.id && (
            <Button
              variant={'link'}
              onClick={() => onLogout(ROUTES.LOGIN)}
              className={'block px-0 text-muted-foreground'}
            >
              {'Continue with a different account'}
            </Button>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default HomePage;
